<template>
<div class="p-grid">
	<div class="p-col-fixed" style="width:150px">
		<div class="box"></div>
	</div>
	<div class="p-col">
		<div class="box">
      <Card style="width: 50em">
        <template #title>
            {{ i18n.$t('Change Password') }}
        </template>
        <template subtitle>
          <p class="mt-4 text-left">
            {{ i18n.$t('Email') }}
          </p>
        </template>
        <template #content>
          <form>
            <div class="form-input">
              <InputText type="text" v-model="email" @focus="isError = null" />
            </div>
            <div class="form-input p-mb-3">
              <Button
                type="button"
                class="font-semibold text-white loading-btn rounded p-mt-3 w-full"
                @click="onSave"
              >
                <spinner v-if="isLoading" color="white" />
                <span v-if="!isLoading">{{ i18n.$t('Change Password') }}</span>
              </Button>
              <Message :severity="msg.severity" :key="msg.content" v-if="typeof isError == 'boolean'">{{
                msg.content
              }}</Message>
            </div>
          </form>
        </template>
      </Card>
    </div>
	</div>
</div>





</template>

<script>
import { ref, computed } from 'vue';
import axios from '@/plugins/axios';
import { LANGS } from '../../store/constant';
import useI18n from '@/plugins/useI18n';
import Spinner from '../../components/UI/Spinner.vue';
export default {
  components: {
    Spinner
  },
  setup() {
    const langs = ref(LANGS);
    const userData = ref();
    const isLoading = ref(false);
    const showResult = ref(null);
    const isError = ref(null);
    const email = ref();
    const { i18n } = useI18n();

    const textError = 'Please fill correct Email';
    const textSuccess = 'Password Change link sent to your mail';

    const messages = [
      { severity: 'error', content: i18n.$t(textError) },
      { severity: 'success', content: i18n.$t(textSuccess) }
    ];
    const msg = computed(() => {
      if (isError.value) return messages[0];
      else return messages[1];
    });


    async function onSave() {
      showResult.value = false;
      isLoading.value = true;
      try {
        const res = await axios.get(`user/changepassword/${email.value}`);
        showResult.value = true;
        isError.value = false;
      } catch {
        showResult.value = true;
        isError.value = true;
      }
      isLoading.value = false;
    }
    return {
      email,
      userData,
      langs,
      isLoading,
      showResult,
      isError,
      onSave,
      i18n,
      msg
    };
  }
};
</script>

<style lang="scss" scoped>
.form-input {
  width: 100%;
  max-width: 300px;
  input {
    width: 100%;
  }
}
</style>
