<template>
  <div>
    <h3>Get Started</h3>
    <Accordion class="accordion-custom" :activeIndex="0">
      <AccordionTab>
        <template #header>
          <i class="pi pi-user"></i>
          <span>Employee</span>
        </template>
        <p v-if="employees.length > 1">
          <Message severity="success" :closable="false">
            you have {{ employees.length - 1 }} employee
          </Message>
        </p>
        <p v-else>
          <Message severity="warn" :closable="false">
            Need to add your employees
          </Message>
        </p>
        <router-link to="/employee/list">
            <Button label="Employees" class="p-button-outlined p-m-2" />
          </router-link>
      </AccordionTab>
      <AccordionTab>
        <template #header>
          <i class="pi pi-list"></i>
          <span>Services</span>
        </template>
        <p v-if="services.length > 0">
          <Message severity="success" :closable="false">
            you have {{ services.length }} services
          </Message>
        </p>
        <p v-else>
          <Message severity="warn" :closable="false">
            Need to add your services
          </Message>
        </p>
        
          <router-link to="/service/list">
            <Button label="services" class="p-button-outlined p-m-2" />
          </router-link>
      </AccordionTab>
      <AccordionTab>
        <template #header>
          <i class="pi pi-cog"></i>
          <span>Setting</span>
        </template>
        <p>
          <router-link to="/agency">
            <Button
              label="Agency"
              class="p-button-outlined p-button-lg p-m-2"
            />
          </router-link>

          <router-link to="/profile/settings">
            <Button
              label="Setting"
              class="p-button-outlined p-button-lg p-m-2"
            />
          </router-link>
        </p>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import useI18n from '@/plugins/useI18n';
import { useStore } from 'vuex';
import {
  FETCH_EMPLOYEES,
  GET_EMPLOYEES,
  GET_USERDATA,
  FETCH_ALL_SERVICES,
  GET_SERVICES
} from '@/store/types';

export default {
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const userData = computed(() => store.getters[GET_USERDATA]);
    const employees = computed(() => {
      //console.log(store.getters[GET_EMPLOYEES].length);
      return store.getters[GET_EMPLOYEES];
    });
    const services = computed(() => store.getters[GET_SERVICES]);
    store.dispatch(FETCH_EMPLOYEES, userData.value.organisation_id);
    store.dispatch(FETCH_ALL_SERVICES);
    return { employees, services };
  }
};
</script>

<style lang="scss" scoped>
.accordion-custom {
  i,
  span {
    vertical-align: middle;
  }

  span {
    margin: 0 0.5rem;
  }
}

.p-accordion p {
  line-height: 1.5;
  margin: 0;
}
</style>